<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      no-filters
      :filter-names="['search']"
      search-label="search"
      @filterOption="onFiltersChange"
    >
      <template v-slot:actions="{ item }">
        <v-icon
          v-if="$admin.can('data-sims.edit')"
          class="mr-2"
          small
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="$admin.can('data-sims.delete')"
          class="mr-2"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>

      <!-- add Filters -->
      <!-- <template #addFilters>
        <v-card v-if="authUser.isAdmin">
          
        </v-card>
      </template> -->
      <!-- add Filters -->

      <!-- Action Modals -->
      <template #actionModals>
        <div>
          <create-data-sims
            v-if="$admin.can('data-sims.create')"
            :filter-options="options"
          />
          <edit-data-sims
            v-if="$admin.can('data-sims.edit') && editModal"
            :is-visible-dialog="editModal"
            :toggle-edit="toggleEditModal"
            :data-sim="selectedRow"
          />
          <confirmation-modal
            v-if="$admin.can('data-sims.delete')"
            :is-visible="deleteModal"
            :toggle="toggleDeleteModal"
            :row="selectedRow"
          />
        </div>
      </template>
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    CreateDataSims: () => import("./dialogs/CreateDataSim.vue"),
    EditDataSims: () => import("./dialogs/EditDataSim.vue"),
    ConfirmationModal: () =>
      import("@/components/Modals/ConfirmationModal.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      filters: {},
      subFilters: {},

      headers: [
        {
          text: this.$t("id"),
          align: "left",
          sortable: true,
          value: "id",
        },
        {
          text: this.$t("network"),
          align: "left",
          sortable: false,
          value: "network_name",
          width: "15%",
        },
        {
          text: this.$t("country_code"),
          align: "left",
          sortable: false,
          value: "country_code",
          width: "15%",
        },
        {
          text: this.$t("msisdn"),
          align: "left",
          sortable: false,
          value: "msisdn",
          width: "15%",
        },
        {
          text: this.$t("sim_imsi"),
          align: "left",
          sortable: false,
          value: "sim_imei",
          width: "15%",
        },
        {
          text: this.$t("status"),
          align: "left",
          sortable: false,
          value: "status",
          width: "15%",
        },
        ...(this.$admin.hasAccessTo("data-sims.delete") ||
        this.$admin.hasAccessTo("data-sims.edit")
          ? [
              {
                text: this.$t("actions"),
                align: "left",
                sortable: false,
                value: "actions",
              },
            ]
          : []),
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "dataSims/isLoadingDataSims",
      meta: "dataSims/meta",
      list: "dataSims/listDataSims",
    }),
    _headers() {
      // computed headers for showing columns to role based
      return this.headers.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },
  },
  watch: {
    options() {
      this.fetchData();
    },
  },
  beforeDestroy() {
    const params = {
      name: "data_sims",
      filters: {
        page: this.options.page,
        itemsPerPage: this.options.itemsPerPage,
        search: this.options.search || undefined,
      },
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch("dataSims/list", this.options).then(() => {
        if (this.options.page > (this.meta?.lastPage || 1)) {
          this.options.page = 1;
        }
      });
    },

    // Confirmation of Delete Row
    async deleteSelectedRow(row) {
      try {
        await this.$store.dispatch("dataSims/destroy", row);
        this.$store.dispatch("alerts/success", this.$t("deleted_successfully"));
        this.toggleDeleteModal("close");
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
  },
};
</script>
